import React, {useMemo} from 'react';
import {TextAutoComplete} from '../../../componentsLib/Basic';
import {HoopsPropTypes} from '../../../componentsLib';
import {sortSizes} from '../../../utils';

export const VariantSizeList = ({item}) => {
  const sizes = useMemo(() => sortSizes(item.product.variants.filter((variant) => variant.color === item.color).map((v) => v.size) ?? []), [item.color, item.product.variants]);

  return (
    <TextAutoComplete
      value={item.size}
      allowAnyValue
      options={sizes}
      emptyList={'No sizes available'}
      onChange={item.setSize}
    />
  );
};

VariantSizeList.propTypes = {item: HoopsPropTypes.object};
